<template>
  <v-card>
    <v-card-text>
      <div class="title">Welcome to the new "Cart Manager"</div>
      <div >This will become the home for everything financial. We will let you know as new features are added.</div>
    </v-card-text>
    <v-card-text>
      <v-list>
        <template v-for="(tab, i) in tabs">
          <v-list-item two-line :key="`item-${i}`" :to="tab.to">
            <v-list-item-content>
              <v-list-item-title>{{ tab.text }}</v-list-item-title>
              <v-list-item-subtitle>{{tab.description}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn color="color3" text icon :to="tab.to">
                <v-icon>fas fa-caret-right</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="`divider-${i}`"></v-divider>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['username'],
  computed: {
    tabs () {
      return [
        { text: 'Promo Codes', description: 'Create, Manage, and track you promo codes', to: { name: 'cart-manager-promos', params: { username: this.username } }, show: true },
        { text: 'Refund Policies', description: 'Create custom refund polices', to: { name: 'cart-manager-refund-policies', params: { username: this.username } }, show: true },
        { text: 'Products (Beta)', description: 'Create a range of products to be used as add-ons or sell in your store', to: { name: 'cart-manager-products', params: { username: this.username } }, show: true }
      ].filter(f => f.show)
    }
  }
}
</script>
